<template>
    <div>
        <div v-if="loading" class="d-flex align-items-center justify-content-center" style="height: 350px">
            <b-spinner v-for="variant in variants" :key="variant" :variant="variant" class="mr-1" type="grow" />
        </div>
        <div v-else>
            <div class="row justify-content-center">
                <b-card class="col-md-8">
                    <div class="row justify-content-center">
                        <div cols="3" class="text-center">
                            <b-avatar size="100" :src="participantdetail.user.thumbnail" :text="avatarText(participantdetail.user.name)" />
                            <h3>{{ participantdetail.user.name }}</h3>
                            <h5 class="text-secondary">{{ participantdetail.user.user_name }}</h5>
                            <b-badge variant="info" class="badge-glow"> {{ participantdetail.status }} </b-badge>
                        </div>
                    </div>
                    <hr />
                    <div class="row" v-if="participantdetail.post !== null">
                        <div class="text-center col-md-4" v-if="participantdetail.post.file.mime_type == 'video/vimeo'" ref="playerContainer">
                            <div>
                                <h4 class="text-secondary">{{ participantdetail.post.title }}</h4>
                            </div>
                            <div>
                                <video id="videoPlayer" controls width="240" height="400" class="plyr">
                                    <source :src="participantdetail.post.file.download[1].link" type="video/mp4" />
                                </video>
                            </div>
                        </div>
                        <div class="text-center col-md-4" v-else-if="participantdetail.post.file.mime_type == 'image/png'" ref="playerContainer">
                            <div>
                                <h4 class="text-secondary">{{ participantdetail.post.title }}</h4>
                            </div>
                            <div>
                                <!-- <video id="videoPlayer" controls width="240" height="400" class="plyr">
                                    <source :src="participantdetail.post.file.download[1].link" type="video/mp4" />
                                </video> -->
                                <b-img width="240" height="300" thumbnail fluid :src="participantdetail.post.file ? participantdetail.post.file.thumbnail : 'https://via.placeholder.com'" />
                            </div>
                        </div>
                        <div class="col-md-8">
                            <div class="row navBar justify-content-center">
                                <div class="col-md-3 text-center">
                                    <h4 class="font-weight-bolder">Views</h4>
                                    <b-badge variant="secondary" class="badge-glow">
                                        <span class="font-weight-bolder view">{{ participantdetail.post.views }}</span>
                                    </b-badge>
                                </div>
                                <div class="col-md-3 text-center">
                                    <h4 class="font-weight-bolder">Text</h4>
                                    <span v-if="participantdetail.post.text">{{ participantdetail.post.text }}</span>
                                    <span v-else>...</span>
                                </div>
                                <div class="col-md-3 text-center">
                                    <h4 class="font-weight-bolder">Status</h4>
                                    <b-badge variant="success" class="badge-glow">
                                        <span class="view">{{ participantdetail.post.status }}</span>
                                    </b-badge>
                                </div>
                                <div class="col-md-3 text-center">
                                    <h4 class="font-weight-bolder">Created at</h4>
                                    <b-badge variant="secondary" class="badge-glow">
                                        <span class="view">{{ postCreatedAt }}</span>
                                    </b-badge>
                                </div>
                            </div>
                            <div class="row justify-content-center">
                                <div class="col-md-10 text-center">
                                    <h3 class="view">Votes</h3>
                                    <span class="vote">
                                        {{ participantdetail.votes_count }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-else-if="participantdetail.story !== null">
                        <div class="text-center col-md-4" v-if="participantdetail.story.video.mime_type == 'video/vimeo'" ref="playerContainer">
                            <!-- <div>
                                <h4 class="text-secondary">{{ participantdetail.story.status }}</h4>
                            </div> -->
                            <div>
                                <video id="videoPlayer" controls width="240" height="400" class="plyr">
                                    <source :src="participantdetail.story.video.download[1].link" type="video/mp4" />
                                </video>
                            </div>
                        </div>
                        <div class="col-md-8">
                            <div class="row navBar justify-content-center">
                                <div class="col-md-3 text-center">
                                    <h4 class="font-weight-bolder">Views</h4>
                                    <b-badge variant="secondary" class="badge-glow">
                                        <span class="font-weight-bolder view">{{ participantdetail.story.views }}</span>
                                    </b-badge>
                                </div>
                                <!-- <div class="col-md-3 text-center">
                                    <h4 class="font-weight-bolder">Text</h4>
                                    <span v-if="participantdetail.post.text">{{ participantdetail.story.text }}</span>
                                    <span v-else>...</span>
                                </div> -->
                                <div class="col-md-3 text-center">
                                    <h4 class="font-weight-bolder">Status</h4>
                                    <b-badge variant="success" class="badge-glow">
                                        <span class="view">{{ participantdetail.story.status }}</span>
                                    </b-badge>
                                </div>
                                <div class="col-md-3 text-center">
                                    <h4 class="font-weight-bolder">Created at</h4>
                                    <b-badge variant="secondary" class="badge-glow">
                                        <span class="view">{{ participantdetail.story.created_at }}</span>
                                    </b-badge>
                                </div>
                            </div>
                            <div class="row justify-content-center">
                                <div class="col-md-10 text-center">
                                    <h3 class="view">Votes</h3>
                                    <span class="vote">
                                        {{ participantdetail.votes_count }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-card>
            </div>
            <b-row class="justify-content-center" v-if="participantdetail.videos.length > 0">
                <h2 class="font-weight-bolder">Videos</h2>
            </b-row>
            <b-row v-if="participantdetail.videos.length > 0">
                <b-card v-for="video in participantdetail.videos" :key="video.id" class="col-md-4 ml-50">
                    <b-row class="justify-content-start mb-1">
                        <b-badge v-if="video.is_published == 1" variant="primary">
                            <feather-icon icon="StarIcon" class="mr-25" />
                            <span>Published</span>
                        </b-badge>
                        <b-badge v-else variant="info">
                            <feather-icon icon="XIcon" class="mr-25 font-weight-bolder" />
                            <span class="font-weight-bolder">Not published</span>
                        </b-badge>
                    </b-row>
                    <b-row class="justify-content-center">
                        <b-col cols="8" class="text-center">
                            <video id="videoPlayer" controls width="340" height="400" class="plyr">
                                <source :src="video.video.download[1].link" type="video/mp4" />
                            </video>
                        </b-col>
                        <b-col cols="4" class="text-center pr-1 navBar2">
                            <b-badge variant="light-info view2"> Video name </b-badge>
                            <h5 class="mt-1">{{ video.name }}</h5>
                            <hr />
                            <b-badge variant="light-info view2"> Song name </b-badge>
                            <h5 class="mt-1">{{ video.song.name }}</h5>
                            <hr />
                            <b-badge variant="light-info view2"> Description </b-badge>
                            <h5 class="mt-1">{{ video.description }}</h5>
                        </b-col>
                    </b-row>

                    <b-row v-if="video.is_published == 1" class="justify-content-center mt-2">
                        <b-button @click="cancelPublishVideoHandler(video.id)" class="col-12" v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="gradient-primary" pill> <h3 class="font-weight-bolder">Cancel</h3> </b-button>
                    </b-row>
                    <b-row v-else class="justify-content-center mt-2">
                        <b-button @click="publishVideoHandler(video.id)" class="col-12" v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="gradient-info" pill> <h3 class="font-weight-bolder text-muted">Publish</h3> </b-button>
                    </b-row>
                </b-card>
            </b-row>
        </div>
    </div>
</template>

<script>
import { BSpinner, BFormCheckbox, BFormCheckboxGroup, BImg, BCard, BRow, BCol, BFormInput, BFormSelect, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination } from "bootstrap-vue";
import store from "@/store";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import PostRepository from "@/abstraction/repository/postRepository";
// import PostListFilters from "./PostListFilters.vue";
import userStoreModule from "../../user/userStoreModule";
import VideoPlayer from "@/views/components/video/VideoPlayer";
import { avatarText } from "@core/utils/filter";
import vSelect from "vue-select";
import router from "@/router";
import Ripple from "vue-ripple-directive";
import useContestList from "../useContestList";
import ContestRepository from "@/abstraction/repository/contestRepository";
const contestRepository = new ContestRepository();

// repository
const postRepository = new PostRepository();
export default {
    directives: {
        Ripple,
    },
    components: {
        BSpinner,
        BFormCheckbox,
        BFormCheckboxGroup,
        // PostListFilters,
        BImg,
        VideoPlayer,
        BCard,
        BRow,
        BCol,
        BFormInput,
        BFormSelect,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        vSelect,
    },

    setup() {
        onMounted(async () => {});
        const loading = ref(true);

        const variants = ref(["primary", "secondary", "danger", "warning", "success", "info", "light", "dark"]);
        const participantdetail = ref();
        const postCreatedAt = ref();
        const { showContestGroup, showParticipant } = useContestList();
        showParticipant(router.currentRoute.params.participantId).then((res) => {
            console.log("HElooo", res);
            participantdetail.value = res;
            postCreatedAt.value = participantdetail.value.post ? participantdetail.value.post.created_at.substring(0, 10) : null;
            loading.value = false;
        });
        const publishVideoHandler = (videoId) => {
            console.log("videoId");
            console.log(videoId);
            let data = {
                is_published: 1,
            };
            // loading.value = true;
            contestRepository.publishAParticipantVideo(data, videoId).then((res) => {
                console.log(res);
                // showParticipant(router.currentRoute.params.participantId).then((res) => {
                //     participantdetail.value = res;
                //     postCreatedAt.value = participantdetail.value.post ? participantdetail.value.post.created_at.substring(0, 10) : null;
                //     loading.value = false;
                // });
            });
        };
        const cancelPublishVideoHandler = (videoId) => {
            let data = {
                is_published: 0,
            };
            contestRepository.publishAParticipantVideo(data, videoId).then((res) => {
                console.log(res);
                // loading.value = true;
                // showParticipant(router.currentRoute.params.participantId).then((res) => {
                //     participantdetail.value = res;
                //     postCreatedAt.value = participantdetail.value.post ? participantdetail.value.post.created_at.substring(0, 10) : null;
                //     loading.value = false;
                // });
            });
        };
        return {
            participantdetail,
            avatarText,
            variants,
            loading,
            postCreatedAt,
            publishVideoHandler,
            cancelPublishVideoHandler,
        };
    },
    methods: {},
};
</script>

<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}
.view {
    margin-top: 50px;
    font-size: 25px;
    font-weight: 600;
    color: rgb(255, 255, 255);
}
.view2 {
    margin-top: 25px;
    font-size: 25px;
    font-weight: 600;
    color: rgb(255, 255, 255);
}
.vote {
    margin-top: 50px;
    font-size: 50px;
    font-weight: 600;
    color: rgb(255, 0, 0);
}
.navBar {
    background-color: rgba(47, 49, 65, 0.583);
    box-shadow: 1px 0px 5px #424242;
    border-radius: 10px;
    padding: 15px;
}
.navBar2 {
    background-color: rgba(73, 73, 74, 0.583);
    // box-shadow: 1px 0px 5px #424242;
    border-radius: 10px;
    padding: 15px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

a {
    color: $red;
}
</style>

import axios from "axios";
import url from "../url";
import { getArray, setQuery, getJson, setData } from "../resources/contestPostResource";

export default class postRepository {
    async index(data) {
        const params = setQuery(data);
        const response = await axios.get(url("indexPosts"), { params });
        if (response.status === 200) {
            return getArray(response.data);
        }
    }
    async indexContestsPosts(data, contestId) {
        console.log(data);
        const params = setQuery(data);
        const response = await axios.get(url("indexContestsPosts", { contestId: contestId }), { params });
        if (response.status === 200) {
            return getArray(response.data);
        }
    }
    async indexContestsStories(data, contestId) {
        const params = setQuery(data);
        const response = await axios.get(url("indexContestsStories", { contestId: contestId }), { params });
        if (response.status === 200) {
            return getArray(response.data);
        }
    }

    async show(postId) {
        const response = await axios.get(url("showPost", { post: postId }));
        if (response.status === 200) {
            return getJson(response.data.data);
        }
    }

    async store(data) {
        const json = setData(data);
        const response = await axios.post(url("storePost"), json);
        if (response.status === 201) {
            return getJson(response.data);
        }
    }

    async update(postId, data) {
        const json = setData(data);
        const response = await axios.put(url("updatePost", { post: postId }), json);
        if (response.status === 200) {
            return response.data.data;
        }
    }

    async verify(postId, data) {
        const response = await axios.put(url("verifyPost", { post: postId }), data);
        if (response.status === 200) {
            return response.data.data;
        }
    }

    async pin(postId) {
        const response = await axios.put(url("changePinPost", { post: postId }));
        if (response.status === 200) {
            return response.data.data;
        }
    }

    async delete(postId) {
        await axios.delete(url("destroyPost", { post: postId }));
    }
}
